.siteFooter {
	background: #000;
	padding: 30px 20px 10px 20px;
	margin-top: 50px;
	color: white;
}

.footerBlocks {
	gap: 30px;
}

.footerBlocks .footerBlock {
	width: calc(50% - 15px);
	float: left;
}

.footerTitle {
	margin-bottom: 15px;
	font-weight: 700;
	font-size: 15px;
	color: white;
}

.footerMenu li a {
	display: inline-block;
	padding: 4px 0;
	color: white;
	font-size: 14px;
}

.footerMenu li svg {
	height: 20px;
	width: 20px;
	display: inline-block;
	margin-right: 10px;
}

.footerIconLink {
	gap: 10px;
}

.footerSection {
	margin-top: 15px;
	padding: 15px;
	border-top: 1px solid #000;
}

/* Desktop CSS */
.footerContainer {
	margin-top: 20px;
	background-color: #000;
}
.imgBoxText .imgBoxTitle {
	font-size: 18px;
	color: white;
}
.imgBoxText .imgBoxDesc {
	font-size: 13px;
	color: white;
}
.imgBoxImg {
	min-height: 95px;
}
.imgBoxImg svg{
	width: 80px;
}
.footerTabCol h5 {
	color: white;
	font-weight: bold;
	font-size: 14px;
}
.footerTabCol ul li a {
	color: #dedbdb;
	font-size: 14px;
}
.copyright p {
	font-size: 14px;
	color: white;
}
.socialIcon svg{
	height: 15px;
	width: 15px;
}
.whatsAppLink{
	color: white;
	font-size: 14px;
}
.copyrightReward{
	color: rgb(134, 197, 255);
}

.linkContainer{
	color: white;
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: end;
	gap: 5px;
}
.linkContainer b{
	font-size: 12px;
	color: #b1aeae;
}
a{
	font-size: 12px !important;
	color: #b1aeae !important;
	white-space: nowrap;
}

a{
	white-space: nowrap;
}

.leftSectionContainer{
	color: white;
	font-size: 13px;
}

.footerLinksComponents{
	display: flex;
	justify-content: end;
}

@media (max-width: 992px){
	.footerLinksComponents{
		justify-content: start;
	}
}
@media (max-width: 530px){
	.footerLinksComponents{
		flex-direction: column;
	}
}