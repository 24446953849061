.mainContainer {
    background-color: #EEEEEE;
    padding: 50px 0px;
    position: relative;
}

.darkenImage {
    filter: brightness(85%);
}

.collectionImageText {
    position: absolute;
    bottom: 0px;
    z-index: 20;
    left: 30px;
    line-height: 1.2;
    font-family: "Montserrat", sans-serif;
    color: white;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    /* Added text-align */
}

.collectionTextBox {
    max-width: 50%;
    /* min-height: 0; */
    /* max-height: 100%; */
    padding: 0;
    position: relative;
}

.categoryName {
    position: absolute;
    text-decoration: none;
    color: black;
    bottom: -5px;
    background-color: white;
    width: 90%;
    padding: 8px;
    font-size: 12px;
    font-weight: 700;
}

.imageResponsive {
    min-width: 100%;
    object-fit: cover;
    height: 100%;
}

.imageResponsive:hover {
    transform: scale(1.1);
    transition: transform 0.5s;
    cursor: pointer;
}

.categoryCollection {
    display: flex;
    padding: 0px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* Added align-items */
    max-width: 100%;
    overflow: hidden;
}

@media (max-width: 767px) {

    .collectionTextBox {
        max-width: 90%;
        margin: 0 auto;
        /* min-height: 0; */
        /* max-height: 100%; */
        padding: 0;
        position: relative;
    }

}