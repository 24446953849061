.sliderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  background: white;
  width: 1300px;
  max-width: 90%;
  margin: 10px 50px;
}

.reviewBtn{
  background: var(--SECONDARY_COLOR);  border: none;
  border-radius: 5px;
}

.brandSilder {
  display: grid;
  margin: 10px 0px;
}

.sliderItem {
  min-width: 280px;
  max-width: 280px;
  height: 330px;
}

@media screen and (min-width: 700px) {
  .sliderContainer {
    flex-direction: row;
  }

  .carousalBody{
    padding: 25px;
  }
}
