.pointsContainer{
    padding: 10px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.pointHeading{
    font-size: 14px;
    font-weight: bold;
}

.pointDesc{
    font-size: 12px;
    font-weight: 400;
}

.pointIcon{
    border-radius: 100%;
    min-height: auto;
    padding: 2px;
    min-width: 40px;
    max-width: 40px;
}