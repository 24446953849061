.loginLayerBox{
    background: rgba(0, 0, 0, 0.50);
    z-index: 1000;
}
.loginContainer{
    background: #FFF;
    border-radius: 6px;
    min-height: 470px;
    height: 470px;
    width: 100%;
    max-width: 800px;
}
.loginGradientBox{
    background: var(--SECONDARY_COLOR);}
.loginCarousalImg{
    height: 100%;
}
.loginGradientBox h2{
    font-size: 18px;
    color: #000;
    margin: 0px;
    font-weight: bold;
}
.loginGradientBox p{
    font-size: 14px;
    font-weight: 400;
    color: #2e2e2e;
}
.closeLogin{
    font-size: 20px;
    height: 40px;
    width: 40px;
    color: #000;
    right: 10px;
    top: 10px;
    font-weight: 800;
}
.loginTitle{
    font-size: 25px;
}
.loginDesc{
    font-size: 14px;
    color: #000;
}
.inputField{
    height: 50px;
    border: 1px solid #DDD;
    border-radius: 4px;
    padding: 10px;
    font-size: 15px;
    color: #111;
    font-weight: 500;
}
.loginFilledBtn, .loginUnfilledBtn{
    height: 56px;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 700;
    border: 1px solid var(--PRIMARY_COLOR);
}
.loginFilledBtn{
    border: 2px solid black;
    background: var(--SECONDARY_COLOR);    color: var(--PRIMARY_COLOR);
}
.loginUnfilledBtn{
    border: 2px solid black;
    background-color: var(--PRIMARY_COLOR);
    color: var(--SECONDARY_COLOR);
}
.alreadyTxt, .privacyTxt{
    font-size: 14px;
    color: #111;
}
.loginLink{
    color: black;
    font-weight: bold;
    font-size: 14px;
}
.changeNumber{
    color: var(--PRIMARY_COLOR)
}
.resendOTp{
    color: #009CDE;
}
.resendOTp, .changeNumber{
    font-size: 16px;
    font-weight: 600;
    z-index: 5;
}