.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    position: relative;
    max-width: 70%;
    max-height: 70%;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    border-radius: 50%;
    background: white;
    border: none;
    color: black;
    cursor: pointer;
    z-index: 10;
}

.eventImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.countdownWrapper {
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 320px;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.91);
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 10;
    display: flex;
    justify-self: center;
    align-items: center;
    white-space: nowrap;
}

.countdownText {
    font-size: 40px;
    margin-right: 5px;
    color: white;
}

.countdownTimer {
    font-size: 40px;
    font-weight: bold;
    color: red;
}

@media (max-width: 768px) {
    .modal {
        max-width: 100%;
        max-height: 80%;
    }
    .closeButton {
        top: 5px;
        right: 5px;
    }
    .countdownWrapper {

    }
}